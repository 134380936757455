export const ADMIN_API = {
    LOGIN: 'login',
    GET_ROLE: 'getRole',
    CHANGE_PASSWORD: 'admin/changePassword',
    DOWN_LINE_LIST: 'downLineList',
    PLAYER_CREATE: 'downLineList/player/create',
    PLAYER_GET_PROFILE: 'downLineList/player/getProfile',
    PLAYER_BET_HISTORY: 'downLineList/player/betHistory',
    PLAYER_BET_LIST: 'downLineList/player/getBetList',
    PLAYER_BET_LIST_CHEATS: 'sports/getCheatsBets',
    PLAYER_ACTIVE: 'downLineList/player/active',
    AGENT_CREATE: 'downLineList/agent/create',
    AGENT_UPDATE_INFO: 'downLineList/agent/updateInfo',
    PLAYER_UPDATE_INFO: 'downLineList/player/updateInfo',
    PLAYER_BLOCK: 'downLineList/player/cheatBlock',
    AGENT_GET_LIST: 'downLineList/agent/getList',
    CREDIT_REF: 'downLineList/addCreditRef',
    SPORTS_LIMIT: 'downLineList/addSportLimit',
    REPORT: {
        DOWN_LINE: 'report/downline',
        MARKET_REPORT: 'report/marketReport',
        PL_MARKET_REPORT: 'report/profitLossByMarket',
        NEW_MARKET_REPORT: 'report/marketReportNew',
        DOWN_LINE_CASINO: 'report/downlineCasino',
        ACCOUNT_STATEMENT: 'report/accountStatment',
        USER_LIST: 'report/userList',
        STATEMENT_BET_VIEW: 'myAccount/statementBetView',
        DW:"report/deposit-withdrawal",
        SPORTS_PROFIT_LOST:"report/sportsProfitLost",
        CASINO_PROFIT_LOST:"report/casinoProfitLost",
    },
    MY_ACCOUNT: {
        GET_PROFILE: 'myAccount/getProfile',
        GET_ACTIVITIES: 'myAccount/getActivities',
        GET_STATEMENTS: 'myAccount/getStatements',
    },
    BET_LIST: {
        LIST_LIVE: 'betList/liveList',
        LIST: 'betList/list',
        MATCH_LIST: 'betList/matchList'
    },
    RISK: 'risk',
    RISK_BET_LIST: 'risk/getBetList',
    RISK_DELETE_BET: 'risk/deleteBet',
    RISK_GET_BLOCK: 'risk/getBlockMatch',
    RISK_UPDATE_BLOCK: 'risk/blockMatch',
    RISK_ADMIN_BOOK_LIST: 'risk/getBetListAdminBook',
    BANKING: {
        GET_BALANCE: 'banking/master/getBalance',
        ADD_BALANCE: 'banking/master/addBalance',
        GET_LIST: 'banking/getList',
        UPDATE: 'banking/update',
        METHODS: {
            GET_ALL: 'banking/method/getAll',
            GET_ONE: 'banking/method/getOne',
            UPDATE: 'banking/method/updateMethod',
            ADD: 'banking/method/add',
        },
    },
    PRIVILEGES:{
        GET: 'privileged/getUserRole',
        UPDATE: 'privileged/updateUserRole'
    },
    MARKET: {
        LIST: 'market/list',
        BLOCK: 'market/block'
    },
    SPORTS: {
        LIST: 'sports/getList',
        ACTIVE_SPORT: 'sports/activeSport'
    },
    ONLINE_PAYMENT : {
        // onlinePayment/deposit/getList
        DEPOSIT: {
            GET_LIST: 'onlinePayment/deposit/getList',
            APPROVE_DEPOSIT: 'onlinePayment/deposit/approveDeposit',
        },
        WITHDRAW: {
            GET_LIST: 'onlinePayment/withdrawal/getList',
            APPROVE_WITHDRAW: 'onlinePayment/withdrawal/approveWithdrawal',
        },
    },
    SETTING: {
        USERS:{
            GET_USER_COUNT : 'totalPlayerCount',
            GET_USER_LIST_BY_DOWNLINE : 'getUserListByDownline',
        },
        BANNER: {
            GET_LIST: 'setting/banner',
            GET_ONE: 'setting/banner/getOne',
            CREATE: 'setting/banner/create',
            UPDATE: 'setting/banner/update',
            DELETE: 'setting/banner/delete',
        },
        DASHBOARD: {
            GET_LIST: 'setting/dashboard',
            GET_ONE: 'setting/dashboard/getOne',
            CREATE: 'setting/dashboard/create',
            UPDATE: 'setting/dashboard/update',
            DELETE: 'setting/dashboard/delete',
        },
        MANAGE_CASINO: {
            GET_LIST: 'setting/manageCasino',
            CREATE: 'setting/manageCasino/create'
        },
        PREMIUM_HISTORY: {
            GET_LIST: 'setting/premiumHistory',
            LIST_OF_BET: 'setting/premiumHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/premiumHistory/rollBackWinner',
        },
        FANCY_HISTORY: {
            GET_LIST: 'setting/fancyHistory',
            LIST_OF_BET: 'setting/fancyHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/fancyHistory/rollBackWinner',
            LIST_OF_BET_CANCEL: 'setting/fancyHistory/listOfBetCancel',
        },
        MANAGE_PREMIUM: {
            GET_LIST: 'setting/managePremium',
            LIST_OF_BET: 'setting/managePremium/listOfBet',
            DECLARE_WINNER: 'setting/managePremium/declareWinner',
        },
        MANAGE_FANCY: {
            GET_LIST: 'setting/manageFancy',
            LIST_OF_BET: 'setting/manageFancy/listOfBet',
            DECLARE_WINNER: 'setting/manageFancy/declareWinner',
        },
        MATCH_HISTORY: {
            GET_LIST: 'setting/matchHistory',
            ROLL_BACK_WINNER: 'setting/matchHistory/rollBackWinner',
            LIST_OF_CANCEL_MATCH: 'setting/matchHistory/listOfCancel',
        },
        SPORT_MARKET: {
            GET_LIST: 'sports/sportMarket',
            GET_LIST_PLACE_BET: 'sports/sportMarketPlacedBet',
            DECLARE_WINNER: 'sports/declareWinner',
            UPDATE: 'sports/updare',
            UPDATE_PROFIT_LIMIT: 'sports/updateProfitLimit',
            GET_SPORT_USERS_COUNT: 'sports/getUserCount',
            GET_SPORT_BETS_COUNT: 'sports/getBetsCount',
        },
        WEBSITE: {
            ADD: 'setting/website/addWebSite',
            CREATE: 'setting/website',
            UPDATE: 'setting/website/update',
            UPDATE_LINKS: 'setting/website/updateLinks',
            GET_SITE: 'setting/website/getSite',
            GET_SITE_BY_ID: 'setting/website/getSiteById',
            LIST: 'setting/website/list',
            MAINTENANCE: 'setting/website/maintenance',
            GET_DOMAIN: 'setting/website/getDomain',
            ACTIVE : "setting/website/activeDeactiveSite",
            IN_ACTIVE_LIST : "setting/website/getInactiveDomain",
            GET_SPORTS_LIMIT : "setting/website/getSportsLimit",
            UPDATE_SPORTS_LIMIT : "setting/website/updateSportsLimit"
        },
        FILE_UPLOAD: 'setting/fileUpload',
        WHITELABLELIMIT : {
            GET_AGENT_LIST: 'setting/whiteLabelsLimit/getAgentList',
            GET: 'setting/whiteLabelsLimit/getLimit',
            UPDATE: 'setting/whiteLabelsLimit/updateLimit',
            RESET: 'setting/whiteLabelsLimit/resetLimit',
        }
    }
}


export const COLOR_OPTION = [
    { backgroundColor: "#ffcc2f", color: "#222", label: 'Yellow', value: 'yellow' },
    { backgroundColor: "#67f0c2", color: "#222", label: 'Onsen', value: 'onsen' },
    { backgroundColor: "#291e39", color: "#FFF", label: 'Purple', value: 'purple' },
    { backgroundColor: "#DE352C", color: "#FFF", label: 'Red', value: 'red' },
    { backgroundColor: "#5D6699", color: "#000", label: 'Blue', value: 'blue' },
    { backgroundColor: "#000000", color: "#FFF", label: 'Black', value: 'black' },
    { backgroundColor: "#FF3377", color: "#FFF", label: 'Pink', value: 'pink' },
    { backgroundColor: "#FFFEBB", color: "#000", label: 'Light Yellow', value: 'light-yellow' },
    { backgroundColor: "#FF8C00", color: "#FFF", label: 'Dark Orange', value: 'dark-orange' },
    { backgroundColor: "#FFD700", color: "#000", label: 'Gold', value: 'gold' },
    { backgroundColor: "#0CFE09", color: "#313131", label: 'Radioactive Green', value: 'radioactive-green' },
    { backgroundColor: "#708090", color: "#000", label: 'Slategrey', value: 'slategrey' },
    { backgroundColor: "#0D4F8B", color: "#FFF", label: 'Indigo Dye', value: 'indigo-dye' },
    { backgroundColor: "#428139", color: "#FFF", label: 'Green', value: 'green' },
    { backgroundColor: "#00A884", color: "#000", label: 'Persian Green', value: 'persian-green' },
    { backgroundColor: "#51e2f5", color: "#000", label: 'Electric Blue', value: 'electric-blue' },
    { backgroundColor: "#0047ab", color: "#FFF", label: 'Dark Royal Blue', value: 'dark-royal-blue' },
]